<template>
  <div>
    <v-select
    :items="freguesiasFiltered"
    v-model="freguesiaSelecionada"
    label="Freguesias"
    name="freguesias"
    single-line
    item-text="freguesia"
    item-value="id"
    solo>
      <template slot="selection" slot-scope="data">
        {{ data.item.freguesia }} ({{ data.item.count }})
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.freguesia }} ({{ data.item.count }})
      </template>
    </v-select>
    <h1 class="text-center">Agendamentos</h1>
    <v-col v-for="item in agendamentosSelected"
        :key="item.id">
      <agendamento-card
        :item="item">
      </agendamento-card>
    </v-col>
  </div>
</template>

<script>
/* eslint-disable */
  import AgendamentoCard from '../components/AgendamentoCard.vue'
  import mixin from "../plugins/mixin";

  export default {
    name: "Agendamentos",
    mixins: [mixin],
    components: {
      AgendamentoCard,
    },
    data: () => ({
        agendamentos: [],
        agendamentosSelected: [],   // agendamentos actually being shown according to selected freguesia
        freguesiasFiltered: [],     // freguesias that are present in agendamentos, no duplicates
        freguesiaSelecionada: -1,   // selected freguesia, -1 to show all
    }),
    created() {  
    },
    mounted(){
      this.agendamentos = this.getObjeto("agendamentos");
      this.agendamentosSelected = this.getObjeto("agendamentosSelected");
      if (!this.agendamentosSelected) this.agendamentosSelected = this.agendamentos;
      this.freguesiaSelecionada = this.getObjeto("freguesiaSelecionada");
      if (!this.freguesiaSelecionada) this.freguesiaSelecionada = -1;
      this.freguesiasFiltered = this.agendamentos.map(item => item.freguesia.designacao)
                                                .sort((a,b) => a < b ? -1 : (a > b ? 1 : 0)) 
                                                .reduce(function(prev, cur) {
                                                  prev[cur] = (prev[cur] || 0) + 1;
                                                  return prev;
                                                }, {});
      this.freguesiasFiltered = Object.keys(this.freguesiasFiltered).map((k, i) => ({id: i+1, freguesia: k, count: this.freguesiasFiltered[k]}));
      this.freguesiasFiltered.unshift({id: -1, freguesia:"Todas", count: this.agendamentos.length}); // add "all" to top
    },
    methods:{
    },
    watch:{
      freguesiaSelecionada: function(to) {
        if (this.freguesiasFiltered) {
          if (to == -1){
            this.agendamentosSelected = this.agendamentos;
          } else {
            var freguesia = this.freguesiasFiltered.filter(freg => freg.id==to)[0].freguesia;
            this.agendamentosSelected = this.agendamentos.filter(cont => cont.freguesia.designacao == freguesia);
          }
          this.setObjeto("freguesiaSelecionada", to);
          this.setObjeto("agendamentosSelected", this.agendamentosSelected);
        }
      }
    }
  }
</script>