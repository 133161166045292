<template>
  <v-card
    @click.stop="editar = true"
    :id="`c${item.id}`"
    :color="report.recolhido != null ? '#e2fee4' : ''"
    >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
      <v-card-title class="headline">
        {{ item.morada }}
      </v-card-title>
      <v-card-subtitle>
        {{ item.freguesia.designacao }}
      </v-card-subtitle>
      <v-card-text>
        <div v-if="item.obsAgendamento">
          Obs: {{ item.obsAgendamento }}
        </div>
        <div v-if="item.dataHoraAgendamento">
          {{ parseDate(item.dataHoraAgendamento )}}
          {{ dateToHHMM(item.dataHoraAgendamento) }}
        </div>
        Nome: {{ item.nome }}
        <div v-if="item.contacto">
          Contacto: {{ item.contacto }}
        </div>
        <div v-if="item.obsRecolha"
          class="font-weight-bold">
         Motivo: {{ item.obsRecolha}}
        </div>
      </v-card-text>
      <p style="font-size: 13px"></p>
      </div>
      <div class="d-flex align-center">
        <v-avatar class="ma-3" size="80" tile>
          <v-icon
            medium
            dark
            color="green  darken-2"
            tile
            v-if="item.recolhido == null"
          >
            mdi-pencil-plus
          </v-icon>
          <v-icon
            medium
            dark
            color="green  darken-2"
            tile
            v-if="item.recolhido && item.recolhido != null"
          >
            mdi-check
          </v-icon>
          <v-icon
            medium
            dark
            color="red  darken-2"
            tile
            v-if="!item.recolhido && item.recolhido != null"
          >
            mdi-delete-forever
          </v-icon>
        </v-avatar>
      </div>
    </div>

    <v-dialog v-model="editar" max-width="90vw">
      <v-card class="px-2">
        <v-card-title class="headline">Recolhido?</v-card-title>
        <v-layout align-center justify-center>
          <v-btn
            @click="report.recolhido=true"
            class="ma-2"
            large
            :color="report.recolhido ?'success':''"
            >Sim
          </v-btn>
          <v-btn
            @click="report.recolhido=false"
            class="ma-2"
            large
            :color="!report.recolhido ?(report.recolhido == null?'':'error'):''" 
            >Não
          </v-btn>
        </v-layout>
        <v-text-field
          label="Observações"
          v-model="report.obsRecolha"
          name="obs"
          type="text"
          v-if="!report.recolhido && report.recolhido != null"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            class="ma-2 white--text"
            @click="
              (saveAgendamento(report))
              editar = false;
            "
          >
            Salvar
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn
            color="error"
            class="ma-2 white--text"
            text
            @click="
              editar = false;
              report.obsRecolha = '';
              report.recolhido = null;
            "
          >
            Cancelar
            <v-icon right dark>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
import mixin from '../plugins/mixin'

export default {
  name: 'AgendamentoCard',
  props: ['item',],
  mixins: [mixin],
  data: () => ({
    editar: false,
    report: {
      recolhido: null,
      obsRecolha: "",
    },
  }),
  methods: {
    saveAgendamento(report){
      this.item.recolhido = report.recolhido;
      if (this.item.recolhido) {
        this.item.dataHoraRecolha = new Date();
      } 
      this.item.obsRecolha = report.obsRecolha;
      this.item.camiao = this.getObjeto("caminhao");
      this.item.user = this.getObjeto("user");
      this.$http
        .put("agendamentos/" + this.item.id, this.item,{
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        });
    },
  }
};
</script>

<style scoped>

</style>
